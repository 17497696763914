<template>
  <div>
    <!-- <page-header-back :backLink="backLink"></page-header-back> -->

    <v-container fluid>
      <v-row justify="center">
        <v-col sm="12" md="10" lg="10">
          <v-card flat>
            <v-toolbar flat>
              <v-row>
                <v-col cols="12" class="px-0">
                  <v-sheet color="warning" class="text-body-2 white--text text-center py-1 mx-n1">
                    [ 타인의 사진, 음원 등 저작물을 등록할 경우, 저작권법 위반에 해당할 수 있으니 주의해 주세요. ]
                  </v-sheet>
                </v-col>
              </v-row>
            </v-toolbar>
          </v-card>
          <v-card outlined tile>
            <v-card-title>
              미디어 수정
            </v-card-title>
            <v-divider/>
            <v-row justify="center">
              <v-col cols="11">
                  <v-list-item>
                    <v-list-item-title class="mt-3">
                      <v-btn large class="mr-3"
                        v-if="selItem.type == 'A'"
                        color="light-green lighten-5"
                        tile depressed
                      ><v-icon color="primary">mdi-music</v-icon>Audio</v-btn>
                      <v-btn large v-else
                        color="light-green lighten-5"
                        tile depressed
                      ><v-icon color="red">mdi-youtube</v-icon>Youtube</v-btn>

                    </v-list-item-title>
                  </v-list-item>
              </v-col>
              <v-col cols="11" style="align-self: center;text-align:center;" class="py-0">
                  <div v-if="selItem.type == 'Y'" class="pt-3">
                    <youtube :video-id="youtubeId" ref="youtube" @playing="playing"></youtube>
                  </div>
              </v-col>
              <v-col cols="11">
                <v-form ref="form" lazy-validation>
                  <v-list-item v-if="toggle == 0">
                      <audio controls autoplay id="audioControl" style="width:100%" controlsList="nodownload"></audio>
                  </v-list-item>
                  <div class="text-subtitle-1 pl-4" v-if="toggle == 0">
                    <v-icon>mdi-playlist-play</v-icon> <span class="text-caption">drag and drop으로 재생순서만 바꿀 수 있습니다.</span>
                    <v-card elevation="0" v-if="audioFilesList.length > 0">

                        <draggable
                          class="list-group"
                          handle=".handle"
                          v-bind="dragOptions"
                          v-model="audioFilesList"
                        >
                          <transition-group type="transition" name="flip-list">

                            <template v-for="(element, index) in audioFilesList">

                              <v-list-item dense
                                :key="element.order"
                                hide-headers
                                style="border-bottom: 1px solid #d3d3d3"
                              >
                                <v-list-item-icon class="handle mr-2" style="cursor: move;align-self: auto;">
                                  <v-icon>mdi-swap-vertical</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-subtitle>{{ index + 1 }}. {{ element.title }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <!-- <v-divider></v-divider> -->
                                <v-list-item-action>
                                  <v-btn
                                    fab
                                    x-small
                                    color="info"
                                    dark
                                    @click="playAudioFile(index)"
                                  ><v-icon>mdi-play</v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                            </template>
                          </transition-group>

                        </draggable>

                    </v-card>
                  </div>
                  <v-list-item class="mt-3 mb-2">
                    <v-icon>mdi-file-pdf-box</v-icon>
                    <div>
                      <v-btn text class="primary--text" @click="previewOldPdf" v-if="selItem.pdfFileUrl">
                        등록한 PDF 보기
                      </v-btn>
                      <v-btn text class="secondary--text" disabled v-else>
                        등록한 PDF 없음
                      </v-btn>
                    </div>
                    <div style="height:15px;border-left: 1px solid #3d3d3d"></div>
                    <div>
                      <v-btn text class="float-right" @click="pdfChange">
                        {{ pdfChangeFlag ? '변경 취소하기' : '파일 변경하기' }}
                      </v-btn>
                    </div>
                  </v-list-item>
                  <v-list-item v-if="pdfChangeFlag">
                    <v-file-input
                      v-model="pdfFile"
                      @change="pdfFileChange"
                      prepend-icon="mdi-file-pdf-box"
                      accept=".pdf, .txt, .md"
                      placeholder="변경할 PDF 파일을 선택하세요"
                    ></v-file-input>
                    <v-btn text class="ml-3 d-none d-md-flex" @click="previewPdf">
                      <v-icon>mdi-view-carousel-outline</v-icon>
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-select
                      v-model="folder"
                      :items="folders"
                      :rules="[rule.required]"
                      item-text="name"
                      item-value="id"
                      placeholder="시리즈별 폴더를 선택하세요"
                      prepend-icon="mdi-folder"
                      required
                    ></v-select>
                    <v-btn text class="ml-3" @click="dialog = true">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-text-field
                      v-model="mediaTitle"
                      :rules="[rule.required]"
                      placeholder="제목을 입력하세요"
                      prepend-icon="mdi-format-title"
                      required
                    ></v-text-field>
                  </v-list-item>

                </v-form>
              </v-col>
            </v-row>
          </v-card>
          <v-card flat class="mt-5">
            <!-- <v-progress-linear
              :active="audioUploading"
              v-model="progressAudioUpload"
              color="amber"
              height="10"
            ></v-progress-linear> -->
            <div v-if="pdfUploading" class="caption">
              PDF 파일 uploading...
            </div>
            <v-progress-linear
              :active="pdfUploading"
              v-model="progressPdfUpload"
              color="amber"
              height="10"
            ></v-progress-linear>
            <v-card-actions class="d-flex justify-center">
                <v-btn
                  class="mb-5"
                  text
                  :disabled="waitForSave"
                  @click="cancel"
                  large
                >취소</v-btn>
                <v-btn
                  class="mb-5 mr-2"
                  :disabled="waitForSave"
                  tile outlined
                  color="primary"
                  @click="edit"
                  large
                >수정</v-btn>
            </v-card-actions>

          </v-card>
        </v-col>
      </v-row>

      <add-folder-card
        :dialog="dialog"
        :folders="folders"
        :mediaItemsCnt="mediaItemsCnt"
        @closeDialog="closeDialog"
      ></add-folder-card>
    </v-container>

  </div>
</template>
<script>
// import PageHeaderBack from '../PageHeaderBack'
import AddFolderCard from '@/components/widgets/AddFolderCard'
import draggable from 'vuedraggable'

export default {
  components: { AddFolderCard, draggable },
  props: ['selItem'],
  data () {
    return {
      dialog: false,
      mediaTitle: '',
      folder: '',
      backLink: '/repo/library',
      toggle: 0,
      youtubeUrl: '',
      uploadPdfTask: '',
      pdfUploading: false,
      pdfUploaded: false,
      waitForSave: false,
      rule: {
        required: v => !!v || '필수 항목입니다.',
        youtube: v => {
          const pattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
          return pattern.test(v) || '올바른 youtube 주소가 아닙니다.'
        }
      },
      folders: [],
      mediaItemsCnt: {},
      youtubeId: '',
      pdfFile: null,
      pdfOldUrl: '',
      pdfChangeFlag: false,
      progressPdfUpload: 0,
      firebasePdfFileName: '',
      audioFilesList: []
    }
  },
  watch: {
    uploadPdfTask: async function () {
      await this.uploadPdfTask.on('state_changed', sp => {
        this.progressPdfUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
        this.waitForSave = true
      },
      null,
      async () => {
        const downloadURL = await this.uploadPdfTask.snapshot.ref.getDownloadURL()
        if (this.selItem.id) {
          await this.$firebase.firestore().collection('libraries').doc(this.selItem.id).update({
            pdfUrl: downloadURL,
            pdfFilename: this.firebasePdfFileName,
            pdfFileSize: (this.pdfFile) ? this.pdfFile.size : 0
          })
        }
        this.pdfUploading = false
        this.pdfUploaded = true
        this.waitForSave = false

        this.clearData()
      })
    }
  },
  async created () {
    this.read()
    // console.log('created', this.selItem)
    if (this.selItem) {
      this.toggle = (this.selItem.type === 'A') ? 0 : 1
      this.youtubeId = (this.selItem.type === 'Y') ? this.getVideoId(this.selItem.yUrl) : ''
      this.youtubeUrl = (this.selItem.type === 'Y') ? this.selItem.yUrl : ''
      this.mediaTitle = this.selItem.title
      this.folder = this.selItem.folderId
      this.audioFilesList = this.selItem.audioFiles

      this.pdfOldUrl = this.selItem.pdfFileUrl
    }
  },
  computed: {
    player () {
      return this.$refs.youtube.player
    },
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  methods: {
    read () {
      this.getFolders()
      this.getMediaCnt()
    },
    getFolders () {
      let academyid = ''
      try {
        academyid = this.$store.state.user.uid
      } catch (e) {
        academyid = localStorage.acid
      }

      this.$firebase.firestore().collection('academies').doc(academyid).collection('folders')
        .orderBy('name')
        .onSnapshot((sn) => {
          if (sn.empty) {
            this.folders = []
            return
          }

          this.folders = sn.docs.map(v => {
            const item = v.data()
            return {
              id: v.id,
              name: item.name
            }
          })
          // console.log('###', JSON.stringify(this.folders))
        })
    },
    async getMediaCnt () {
      const academyid = this.$store.state.user.uid
      await this.$firebase.firestore().collection('academies').doc(academyid)
        .collection('infos').doc('media')
        .onSnapshot((sn) => {
          if (sn.empty) {
            this.mediaItemsCnt = {}
          } else {
            this.mediaItemsCnt = sn.data()
            // console.log(this.mediaItemsCnt)
          }
        })
    },
    closeDialog (b) {
      this.dialog = false
    },
    cancel () {
      this.$router.push(this.backLink)
    },
    async edit () {
      const newAudioFiles = []
      for (let i = 0; i < this.audioFilesList.length; i++) {
        const file = this.audioFilesList[i]
        const form = {
          title: file.title,
          filename: file.filename,
          size: file.size,
          order: i,
          url: file.url
        }
        newAudioFiles.push(form)
      }
      // console.log(insertForm)

      await this.$firebase.firestore().collection('libraries').doc(this.selItem.id).update({
        title: this.mediaTitle,
        folder: this.folder,
        audioFiles: newAudioFiles
      })

      if (this.pdfFile) {
        await this.uploadPdf(this.pdfFile)
      } else {
        this.clearData()
      }
    },
    uploadPdf (file) {
      const fileName = new Date().getTime()
      const extArr = file.name.split('.')
      const ext = '.' + extArr[extArr.length - 1]

      try {
        const storageRef = this.$firebase.storage().ref()

        this.uploadPdfTask = storageRef.child('pdffiles/' + this.$store.state.user.uid + '/' + fileName + ext).put(file)
        this.firebasePdfFileName = fileName + ext
        this.pdfUploading = true
      } catch (e) {
        console.log(e.message)
      }
    },
    getVideoId (url) {
      if (!url) return ''

      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      const match = url.match(regExp)
      return (match && match[7].length === 11) ? match[7] : false
    },
    playVideo () {
      this.player.playVideo()
    },
    playing () {
      // console.log('we are watching!!!')
    },
    pdfChange () {
      this.pdfChangeFlag = !this.pdfChangeFlag

      if (!this.pdfChangeFlag) {
        this.pdfFile = null
      }
    },
    pdfFileChange (file) {
      //
    },
    previewOldPdf () {
      if (!this.pdfOldUrl) return
      window.open(this.pdfOldUrl, 'OpenPDF')
    },
    previewPdf () {
      if (!this.pdfFile) return

      const url = URL.createObjectURL(this.pdfFile)
      window.open(url, 'OpenPDF')
    },
    clearData () {
      this.$router.push(this.backLink)
    },
    playAudioFile (index) {
      const audio = document.getElementById('audioControl')
      audio.pause()
      audio.currentTime = 0
      audio.src = ''
      if (this.audioFilesList.length <= 0) return

      audio.src = this.audioFilesList[index].url
      audio.load()
    }
  }
}
</script>
